import { ContextInterface } from '../Store/store';

interface ReducerActionInterface {
  type?: string;
  payload?: ContextInterface;
}

const Reducer = (state: ContextInterface, action: ReducerActionInterface) => {
  switch (action.type) {
    case 'CHANGE_LANGUAGE':
      return {
        ...state, changeLanguage: action.payload,
      };
    default:
      return state;
  }
};

export default Reducer;
