import Store from "./src/components/Store/store";
import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax';


export const onServiceWorkerUpdateReady = () => {
		window.location.reload();
};

export const wrapRootElement = ({element}) => {
	return (
		<ParallaxProvider>
			<Store>
				{element}
			</Store>
		</ParallaxProvider>
	)
};
