// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-albums-inrainbows-tsx": () => import("./../../../src/pages/albums/inrainbows.tsx" /* webpackChunkName: "component---src-pages-albums-inrainbows-tsx" */),
  "component---src-pages-albums-makethesea-tsx": () => import("./../../../src/pages/albums/makethesea.tsx" /* webpackChunkName: "component---src-pages-albums-makethesea-tsx" */),
  "component---src-pages-albums-sleepingwithghosts-tsx": () => import("./../../../src/pages/albums/sleepingwithghosts.tsx" /* webpackChunkName: "component---src-pages-albums-sleepingwithghosts-tsx" */),
  "component---src-pages-albums-thegoldenage-tsx": () => import("./../../../src/pages/albums/thegoldenage.tsx" /* webpackChunkName: "component---src-pages-albums-thegoldenage-tsx" */),
  "component---src-pages-albums-tsx": () => import("./../../../src/pages/albums.tsx" /* webpackChunkName: "component---src-pages-albums-tsx" */),
  "component---src-pages-building-tsx": () => import("./../../../src/pages/building.tsx" /* webpackChunkName: "component---src-pages-building-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-design-tsx": () => import("./../../../src/pages/design.tsx" /* webpackChunkName: "component---src-pages-design-tsx" */),
  "component---src-pages-figma-code-integration-tsx": () => import("./../../../src/pages/figma-code-integration.tsx" /* webpackChunkName: "component---src-pages-figma-code-integration-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-komilfo-tsx": () => import("./../../../src/pages/komilfo.tsx" /* webpackChunkName: "component---src-pages-komilfo-tsx" */),
  "component---src-pages-polytech-tsx": () => import("./../../../src/pages/polytech.tsx" /* webpackChunkName: "component---src-pages-polytech-tsx" */),
  "component---src-pages-selfhelp-designingyourlife-tsx": () => import("./../../../src/pages/selfhelp/designingyourlife.tsx" /* webpackChunkName: "component---src-pages-selfhelp-designingyourlife-tsx" */),
  "component---src-pages-selfhelp-tsx": () => import("./../../../src/pages/selfhelp.tsx" /* webpackChunkName: "component---src-pages-selfhelp-tsx" */),
  "component---src-pages-snob-editor-tsx": () => import("./../../../src/pages/snob/editor.tsx" /* webpackChunkName: "component---src-pages-snob-editor-tsx" */),
  "component---src-pages-snob-how-we-use-figma-tsx": () => import("./../../../src/pages/snob/how-we-use-figma.tsx" /* webpackChunkName: "component---src-pages-snob-how-we-use-figma-tsx" */),
  "component---src-pages-snob-onboarding-tsx": () => import("./../../../src/pages/snob/onboarding.tsx" /* webpackChunkName: "component---src-pages-snob-onboarding-tsx" */),
  "component---src-pages-templates-tsx": () => import("./../../../src/pages/templates.tsx" /* webpackChunkName: "component---src-pages-templates-tsx" */),
  "component---src-pages-toki-tsx": () => import("./../../../src/pages/toki.tsx" /* webpackChunkName: "component---src-pages-toki-tsx" */),
  "component---src-pages-tools-dashboard-tsx": () => import("./../../../src/pages/tools/dashboard.tsx" /* webpackChunkName: "component---src-pages-tools-dashboard-tsx" */),
  "component---src-pages-tools-fedro-tsx": () => import("./../../../src/pages/tools/fedro.tsx" /* webpackChunkName: "component---src-pages-tools-fedro-tsx" */),
  "component---src-pages-tools-figma-covers-tsx": () => import("./../../../src/pages/tools/figma-covers.tsx" /* webpackChunkName: "component---src-pages-tools-figma-covers-tsx" */),
  "component---src-pages-tools-figma-font-checker-tsx": () => import("./../../../src/pages/tools/figma-font-checker.tsx" /* webpackChunkName: "component---src-pages-tools-figma-font-checker-tsx" */),
  "component---src-pages-tools-tsx": () => import("./../../../src/pages/tools.tsx" /* webpackChunkName: "component---src-pages-tools-tsx" */),
  "component---src-pages-tsimmes-tsx": () => import("./../../../src/pages/tsimmes.tsx" /* webpackChunkName: "component---src-pages-tsimmes-tsx" */),
  "component---src-pages-union-tsx": () => import("./../../../src/pages/union.tsx" /* webpackChunkName: "component---src-pages-union-tsx" */),
  "component---src-templates-case-template-tsx": () => import("./../../../src/templates/caseTemplate.tsx" /* webpackChunkName: "component---src-templates-case-template-tsx" */)
}

