import React, { createContext, useReducer } from 'react';
import Reducer from '../Reducer/reducer';

export interface ContextInterface {
  changeLanguage: string;
}

const InitialState = {
  changeLanguage: 'EN',
};

const Store = ({ children }: HTMLElement) => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  return (
        <Context.Provider value={{ state, dispatch }}>
        {children}
        </Context.Provider>
  );

};

export const Context = createContext<ContextInterface>(InitialState);
export default Store;
